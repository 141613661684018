<template>
  <div class="center">
    <b-spinner style="width: 5rem; height: 5rem" :label="label" variant="primary"></b-spinner>
  </div>
</template>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
</style>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};
</script>
