var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center"
  }, [_c('b-spinner', {
    staticStyle: {
      "width": "5rem",
      "height": "5rem"
    },
    attrs: {
      "label": _vm.label,
      "variant": "primary"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }