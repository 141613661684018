import api from "../api.service";

class NpgLegalTypesService {
  get PATH() {
    return "nations-legaltypes";
  }
  async getAll() {
    return this.getPaginated(1000, 0);
  }

  async getPaginated(limit, offset) {
    const res = await api.get(`${this.PATH}/`, { params: { limit, offset } });
    return res.data;
  }

  async getOne(id) {
    return (await api.get(`${this.PATH}/${id}`)).data;
  }
}

export default new NpgLegalTypesService();
