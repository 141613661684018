<template>
  <div class="position-relative">
    <div v-if="isLoading || !territorialJurisdiction || !categories" class="spinner-overlay">
      <SpinnerVue />
    </div>
    <div v-if="npgDetails.name">
      <!-- Details -->
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{ $t("NPG") + ":" + npgDetails.name }}</h3>
          </div>
        </div>
        <div v-if="checkIsDataLoaded()" class="card-body">
          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Description") + ":" }}</dt>
            <dd class="col-9">{{ npgDetails.description }}</dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Valid From") + ":" }}</dt>
            <dd class="col-9">
              {{ npgDetails.valid_from ? DateService.format(npgDetails.valid_from, "MMMM Do, YYYY") : "" }}
            </dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Valid To") + ":" }}</dt>
            <dd class="col-9">
              {{ npgDetails.valid_to ? DateService.format(npgDetails.valid_to, "MMMM Do, YYYY") : "" }}
            </dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Territorial Jurisdiction") + ":" }}</dt>
            <dd class="col-9">{{ findTerritorialJurisdiction }}</dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Administrative Structure") + ":" }}</dt>
            <dd class="col-9">{{ administrativeStructureType }}</dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Legal Type") + ":" }}</dt>
            <dd class="col-9">{{ legalType }}</dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Categories - Jurisdictions") + ":" }}</dt>
            <dd class="col-9 d-flex flex-wrap">
              <div v-for="category in getCategoriesToDisplay" :key="category" class="bg-light text-dark rounded p-3 mr-2">
                {{ category }}
              </div>
            </dd>
          </dl>

          <dl class="row border-bottom">
            <dt class="col-3 text-uppercase">{{ $t("Tags") + ":" }}</dt>
            <dd class="col-9 d-flex flex-wrap">
              <div v-for="tag in npgDetails.tags" :key="tag" class="bg-light text-dark rounded p-3 mr-2">{{ tag }}</div>
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-3 text-uppercase">{{ $t("Attachments") + ":" }}</dt>
            <dd class="col-9 d-flex flex-wrap">
              <a :href="npgDetails.legislationattachment_set.length
                ? npgDetails.legislationattachment_set[0].attachment
                : null
                " target="_blank">
                <div v-if="npgDetails.legislationattachment_set.length" class="bg-light text-dark rounded p-3 mr-2">
                  <i class="fas fa-file-pdf text-danger"></i>
                  <span class="ml-2">{{ attachmentLink }}</span>
                </div>
              </a>
            </dd>
          </dl>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <b-button type="submit" variant="warning" size="lg" @click="$router.push({ name: 'manage-npg' })">{{
            $t("Back to NPG table")
          }}</b-button>
          <b-button class="ml-2" type="reset" variant="secondary" size="lg"
            @click="$router.push({ name: 'npg-edit', params: { ID: npgId } })">
            {{ $t("Edit") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerVue from "../../components/loaders/Spinner.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NpgService from "@/core/services/npg/npg.service";
import DateService from "@/core/services/date.service";
import NpgAdministrativeStructureService from "@/core/services/npg/npg-administrative-structure.service";
import NpgLegalTypesService from "@/core/services/npg/npg-legal-types.service";
import { mapState } from "vuex";

export default {
  components: {
    SpinnerVue,
  },

  data() {
    return {
      DateService: DateService,
      npgDetails: [],
      administrativeStructureType: "",
      legalType: "",
      isLoading: true,
    };
  },

  computed: {
    getCategoriesToDisplay() {
      return this.categories.results.filter(({ id }) => this.npgDetails.categories.includes(id)).map(el => el.name);
    },
    ...mapState("npg", ["territorialJurisdiction", "isNpgMasterFetched", "categories"]),
    npgId() {
      return this.$route.params.npgId;
    },
    attachmentLink() {
      const attachmentUrl = this.npgDetails.legislationattachment_set[0].attachment;
      return attachmentUrl.slice(attachmentUrl.lastIndexOf("/") + 1, attachmentUrl.lastIndexOf("?"));
    },
    findTerritorialJurisdiction() {
      if (this.territorialJurisdiction.results) {
        return this.territorialJurisdiction.results.filter(el => el.iso === this.npgDetails.territorial_jurisdiction)[0]
          .name;
      } else return "";
    },
  },

  watch: {
    npgDetails() {
      if (this.npgDetails.administrative_structure) {
        this.loadAdministrativeAndLegalData(this.npgDetails.administrative_structure, this.npgDetails.legal_type);
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("NPG"), route: "alert" },
      { title: this.$t("Detail") },
    ]);
  },

  async created() {
    await this.loadNpgDetails();
  },

  methods: {
    checkIsDataLoaded() {
      return this.npgDetails && this.administrativeStructureType && this.territorialJurisdiction && this.categories;
    },
    async loadNpgDetails() {
      try {
        const [npgData] = await Promise.all([NpgService.getOne(this.npgId)]);
        this.npgDetails = npgData;
      } catch (err) {
        this.errorCatch();
      }
    },
    async loadAdministrativeAndLegalData(administrativeStructureId, legalTypesId) {
      try {
        const [administrativeStructure, legalType] = await Promise.all([
          NpgAdministrativeStructureService.getOne(administrativeStructureId),
          NpgLegalTypesService.getOne(legalTypesId),
        ]);
        this.administrativeStructureType = administrativeStructure.name;
        this.legalType = legalType.name;
        this.isLoading = false;
      } catch (err) {
        this.errorCatch();
      }
    },
    errorCatch() {
      this.$bvToast.toast("Failed to load. Please try later.", {
        title: "Error",
        variant: "danger",
        solid: true,
        noAutoHide: true,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.custom-file-upload .custom-file-input~.custom-file-label {
  border-color: #cbcbcb;
  border-width: 2px;
  border-style: dashed;
  background-color: #f1f1f1;
  height: 4rem;
  padding: 1rem;
}

.custom-file-upload .custom-file-input.is-valid~.custom-file-label {
  border-color: #1bc5bd;
  background-color: #fff;
}

.custom-file-upload .custom-file-input~.custom-file-label[data-browse]::after {
  display: none;
}

.spinner-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-block-start: 5%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.65);
}

.position-relative {
  position: relative;
}
</style>
