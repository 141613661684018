var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_vm.isLoading || !_vm.territorialJurisdiction || !_vm.categories ? _c('div', {
    staticClass: "spinner-overlay"
  }, [_c('SpinnerVue')], 1) : _vm._e(), _vm.npgDetails.name ? _c('div', [_c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(_vm._s(_vm.$t("NPG") + ":" + _vm.npgDetails.name))])])]), _vm.checkIsDataLoaded() ? _c('div', {
    staticClass: "card-body"
  }, [_c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Description") + ":"))]), _c('dd', {
    staticClass: "col-9"
  }, [_vm._v(_vm._s(_vm.npgDetails.description))])]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Valid From") + ":"))]), _c('dd', {
    staticClass: "col-9"
  }, [_vm._v(" " + _vm._s(_vm.npgDetails.valid_from ? _vm.DateService.format(_vm.npgDetails.valid_from, "MMMM Do, YYYY") : "") + " ")])]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Valid To") + ":"))]), _c('dd', {
    staticClass: "col-9"
  }, [_vm._v(" " + _vm._s(_vm.npgDetails.valid_to ? _vm.DateService.format(_vm.npgDetails.valid_to, "MMMM Do, YYYY") : "") + " ")])]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Territorial Jurisdiction") + ":"))]), _c('dd', {
    staticClass: "col-9"
  }, [_vm._v(_vm._s(_vm.findTerritorialJurisdiction))])]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Administrative Structure") + ":"))]), _c('dd', {
    staticClass: "col-9"
  }, [_vm._v(_vm._s(_vm.administrativeStructureType))])]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Legal Type") + ":"))]), _c('dd', {
    staticClass: "col-9"
  }, [_vm._v(_vm._s(_vm.legalType))])]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Categories - Jurisdictions") + ":"))]), _c('dd', {
    staticClass: "col-9 d-flex flex-wrap"
  }, _vm._l(_vm.getCategoriesToDisplay, function (category) {
    return _c('div', {
      key: category,
      staticClass: "bg-light text-dark rounded p-3 mr-2"
    }, [_vm._v(" " + _vm._s(category) + " ")]);
  }), 0)]), _c('dl', {
    staticClass: "row border-bottom"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Tags") + ":"))]), _c('dd', {
    staticClass: "col-9 d-flex flex-wrap"
  }, _vm._l(_vm.npgDetails.tags, function (tag) {
    return _c('div', {
      key: tag,
      staticClass: "bg-light text-dark rounded p-3 mr-2"
    }, [_vm._v(_vm._s(tag))]);
  }), 0)]), _c('dl', {
    staticClass: "row"
  }, [_c('dt', {
    staticClass: "col-3 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Attachments") + ":"))]), _c('dd', {
    staticClass: "col-9 d-flex flex-wrap"
  }, [_c('a', {
    attrs: {
      "href": _vm.npgDetails.legislationattachment_set.length ? _vm.npgDetails.legislationattachment_set[0].attachment : null,
      "target": "_blank"
    }
  }, [_vm.npgDetails.legislationattachment_set.length ? _c('div', {
    staticClass: "bg-light text-dark rounded p-3 mr-2"
  }, [_c('i', {
    staticClass: "fas fa-file-pdf text-danger"
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.attachmentLink))])]) : _vm._e()])])])]) : _vm._e(), _c('div', {
    staticClass: "card-footer d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "warning",
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'manage-npg'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Back to NPG table")))]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "type": "reset",
      "variant": "secondary",
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'npg-edit',
          params: {
            ID: _vm.npgId
          }
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])], 1)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }